import {
  ButtonGroup,
  CircularProgress,
  IconButton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { ThumbUpAlt, ThumbDown } from "@material-ui/icons";

// styles
import useStyles from "./styles";

export const TblHeader = ({ action, notes }) => {
  const classes = useStyles();

  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.header}>Title</TableCell>
        <TableCell className={classes.header}>Description</TableCell>
        <TableCell className={classes.header}>Company</TableCell>
        <TableCell className={classes.header}>Author</TableCell>
        <TableCell className={classes.header}>Date</TableCell>
        {action && (
          <TableCell align="center" className={classes.header}>
            Action
          </TableCell>
        )}
        {notes && <TableCell className={classes.header}>Notes</TableCell>}
      </TableRow>
    </TableHead>
  );
};

export const TblBody = ({ action, data, loading, notes, handleAction }) => {
  const classes = useStyles();

  const rows = data.map(
    (
      { _id, title, description, company, review_notes, created_by, createdAt },
      key
    ) => (
      <TableRow key={key}>
        <TableCell>{title}</TableCell>
        <TableCell>{description}</TableCell>
        <TableCell>{company}</TableCell>
        <TableCell>{created_by?.first_name}</TableCell>
        <TableCell>{createdAt}</TableCell>
        {action && (
          <TableCell align="center">
            <ButtonGroup disableElevation variant="contained">
              <IconButton
                aria-label="approve"
                color="primary"
                onClick={() => handleAction(_id, "approve")}
              >
                <ThumbUpAlt />
              </IconButton>
              <IconButton
                aria-label="reject"
                color="secondary"
                onClick={() => handleAction(_id, "reject")}
              >
                <ThumbDown />
              </IconButton>
            </ButtonGroup>
          </TableCell>
        )}
        {notes && <TableCell>{review_notes}</TableCell>}
      </TableRow>
    )
  );

  return (
    <TableBody>
      {loading ? (
        <TableRow>
          <TableCell colSpan={6}>
            <div className={classes.loader}>
              <CircularProgress />
            </div>
          </TableCell>
        </TableRow>
      ) : (
        rows
      )}
    </TableBody>
  );
};
