import { useEffect, useReducer, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Grid,
  Divider,
  FormControl,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import {
  Business,
  LocationOn,
  Person,
  Update,
  VideoCall,
} from "@material-ui/icons";
import ReactPlayer from "react-player";

// components
import Widget from "../../components/Widget/Widget";
import {
  Preview,
  Typography,
  Upload,
} from "../../components/Wrappers/Wrappers";

// helpers
import { iCompany, iDisplay } from "../../helpers/props";
import { colors } from "../../helpers/utils";

// styles
import useStyles from "./styles";

// data
import {
  getCompany,
  updateCompany,
  uploadLogo,
  uploadVideo,
} from "../../hooks/useRequest";

export default function Company() {
  const classes = useStyles();
  const { id } = useParams();

  const [data, setData] = useState([]);
  const [company, setCompany] = useReducer(
    (prev, next) => ({ ...prev, ...next }),
    iCompany
  );
  const [errors, setErrors] = useReducer(
    (prev, next) => ({ ...prev, ...next }),
    { company_name: false }
  );
  const [response, setResponse] = useReducer(
    (prev, next) => ({ ...prev, ...next }),
    { error: "", message: "" }
  );
  const [display, setDisplay] = useReducer(
    (prev, next) => ({ ...prev, ...next }),
    iDisplay
  );

  const handleUpdate = async () => {
    setDisplay({
      loading: true,
    });
    console.log("DATA :===>", data);
    await updateCompany(id, data);
    setDisplay({
      loading: false,
    });
  };

  const handleUploadVideo = async (e) => {
    const { files } = e.target;

    setDisplay({
      loading: true,
    });

    const formData = new FormData();
    formData.append("video", files[0]);

    await uploadVideo(id, formData);

    setDisplay({
      loading: false,
    });
  };

  const handleUploadLogo = async (e) => {
    const { files } = e.target;

    setDisplay({
      loading: true,
    });

    const formData = new FormData();
    formData.append("image", files[0]);

    await uploadLogo(id, formData);

    setDisplay({
      loading: false,
    });
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;

    // values for update
    setData({
      ...data,
      [name]: value,
    });
    // values for display
    setCompany({
      [name]: value,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // values for update
    setData({
      ...data,
      [name]: value,
    });
    // values for display
    setCompany({
      [name]: value,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await getCompany(id);
      setCompany(data);
    };

    if (!display.loading) {
      fetchData();
    }
  }, [id, display]);

  // console.log("COMPANY :=====>", company);
  //   {
  //     "_id": "66725e4ed3e3a4c65a532a3e",
  //     "company_name": "Emprotech",
  //     "company_logo": "https://emprotech.ams3.digitaloceanspaces.com/empro/logo/1718771473696-em.png?AWSAccessKeyId=DO004GNW7L2GA87F9T4T&Expires=1719672549&Signature=XrMKK4ypsv0RuNbQYC87iOBYGSs%3D",
  //     "company_address": "UK",
  //     "address_street": "Street",
  //     "address_city": "city",
  //     "company_contact_person": "Whitney",
  //     "company_contact_number": "+1 234 2321 2323",
  //     "company_email": "company@emprogroup.co.uk",
  //     "primary_color": "#080836",
  //     "secondary_color": "#551C4C",
  //     "tertiary_color": "#D23D70",
  //     "accent_color": "#cb6ce6",
  //     "overlay_color": "#EAA2FF",
  //     "neutral_color": "#F5F5F5",
  //     "owner": "66725cc7d3e3a4c65a532a3a",
  //     "status": 1,
  //     "created_by": "66725c29d3e3a4c65a532a33",
  //     "intro_video": "https://emprotech.ams3.digitaloceanspaces.com/empro/videos/1718832559835-intro_video.mp4?AWSAccessKeyId=DO004GNW7L2GA87F9T4T&Expires=1719672549&Signature=1jAWuFSvsod4GQ9%2BlHBGIpf3mFk%3D"
  // }

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Widget>
          <div className={classes.logoWrapper}>
            {company?.company_logo ? (
              <img
                src={company?.company_logo}
                alt={company?.company_name}
                className={classes.logo}
              />
            ) : (
              <Typography className={classes.noLogo}>LOGO HERE</Typography>
            )}
          </div>
          <Upload onUpload={handleUploadLogo} loading={display.loading} />
          <Divider className={classes.mb5} />
          <h3 className={classes.header}>
            Name <Business className={classes.iconStyle} />
          </h3>
          <div className={classes.mb5}>
            <FormControl className={classes.formControl}>
              <TextField
                id="name"
                name="company_name"
                label="Business Name"
                value={company?.company_name}
                required
                error={errors.company_name}
                onChange={handleChange}
              />
            </FormControl>
          </div>
          <h3 className={classes.header}>
            Address <LocationOn className={classes.iconStyle} />
          </h3>
          <div className={classes.mb5}>
            <FormControl className={classes.formControl}>
              <TextField
                id="street"
                name="address_street"
                label="Street"
                value={company?.address_street}
                onChange={handleChange}
              />
            </FormControl>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <FormControl className={classes.formControl}>
                  <TextField
                    id="city"
                    name="address_city"
                    label="City"
                    value={company.address_city}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <TextField
                    id="zip"
                    name="address_zip"
                    label="Zip"
                    value={company.address_zip}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </div>
          <h3 className={classes.header}>
            Contact <Person className={classes.iconStyle} />
          </h3>
          <div className={classes.mb5}>
            <FormControl className={classes.formControl}>
              <TextField
                id="person"
                name="company_contact_person"
                label="Name"
                value={company.company_contact_person}
                onChange={handleChange}
              />
            </FormControl>
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <FormControl className={classes.formControl}>
                  <TextField
                    id="email"
                    name="company_email"
                    label="Email"
                    type="email"
                    value={company.company_email}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={5}>
                <FormControl className={classes.formControl}>
                  <TextField
                    id="phone"
                    name="company_contact_number"
                    label="Phone"
                    value={company.company_contact_number}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </div>
          <div className={classes.footer}>
            {display.loading ? (
              <CircularProgress />
            ) : (
              <Button
                variant="contained"
                size="medium"
                color="primary"
                endIcon={<Update />}
                onClick={handleUpdate}
              >
                Update
              </Button>
            )}
          </div>
        </Widget>
      </Grid>
      <Grid item xs={8}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Widget title="Color Scheme">
              <Grid container direction="row" spacing={4}>
                {colors.map((color, key) => (
                  <Preview
                    company={company}
                    color={color}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    key={key}
                  />
                ))}
              </Grid>
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget title="Intro Video">
              {company?.intro_video && (
                <ReactPlayer
                  url={company?.intro_video}
                  playing={true}
                  controls={true}
                  loop={true}
                  muted={true}
                  playsinline={true}
                  width="100%"
                />
              )}
              <div className={classes.btnWapper}>
                <input
                  accept="image/*"
                  id="intro-video-upload"
                  type="file"
                  onChange={handleUploadVideo}
                  hidden
                />
                <label htmlFor="intro-video-upload">
                  {display.loading ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      startIcon={<VideoCall />}
                    >
                      Upload Intro Video
                    </Button>
                  )}
                </label>
              </div>
              {/* <Divider />
              <Table className={classes.table} aria-label="simple table">
                <TableBody>
                  {docs.map((row, key) => (
                    <TableRow key={key}>
                      <TableCell component="th" scope="row">
                        {row.title}
                      </TableCell>
                      <TableCell align="right">{row.status}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table> */}
            </Widget>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
