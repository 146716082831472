import { useState, useEffect, useReducer } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Button,
  Grid,
  FormControl,
  InputLabel,
  IconButton,
  TextField,
  Select,
  MenuItem,
} from "@material-ui/core";
import {
  Add,
  AccountBox,
  Business,
  Person,
  VpnKey,
  Save,
} from "@material-ui/icons";
import MUIDataTable from "mui-datatables";

// context
import { useUserState } from "../../context/UserContext";

// styles
import useStyles from "./styles";

// components
import PageTitle from "../../components/PageTitle";
import EmproPaper from "../../components/Loading/Paper";
import EmproModal from "../../components/Modal";
import EmproSnackbar from "../../components/Snackbar";
import { ConfirmDelete } from "../../components/Dialog";

// data
import {
  addUser,
  deleteUser,
  getUsers,
  getRoles,
  getCompanies,
  getCompany,
  getOrganizations,
} from "../../hooks/useRequest";

import { roles, roleLabel, tblUserHdr } from "../../helpers/utils";
import { iUser, iUserErrors, iDisplay, iResponse } from "../../helpers/props";

export default function Users() {
  const classes = useStyles();

  const { id } = useParams();
  const { userData } = useUserState();

  // console.log("USER ROLE :=============>", userData?.role);
  // console.log("USER COMPANY :=============>", userData?.company);

  const [user, setUser] = useReducer(
    (prev, next) => ({ ...prev, ...next }),
    iUser
  );
  const [errors, setErrors] = useReducer(
    (prev, next) => ({ ...prev, ...next }),
    iUserErrors
  );
  const [response, setResponse] = useReducer(
    (prev, next) => ({ ...prev, ...next }),
    iResponse
  );
  const [display, setDisplay] = useReducer(
    (prev, next) => ({ ...prev, ...next }),
    iDisplay
  );
  const [users, setUsers] = useState([]);
  const [deleted, setDeleted] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [organizations, setOrganizations] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({
      [name]: value,
    });
  };

  const handleDelete = async () => {
    setDisplay({
      dialog: false,
      loading: true,
    });

    // reset error response
    setResponse({
      error: "",
      message: "",
    });

    const promises = deleted.map(async (id) => await deleteUser(id));
    const results = await Promise.all(promises);

    if (results.length) {
      setResponse({
        error: results[0].error,
        message: results[0].message,
      });
    }

    setDisplay({
      loading: false,
      snackbar: true,
    });
  };

  const handleSubmit = async () => {
    let errorCount = 0;

    // company and organization are not required
    const { company, organization, ...rest } = user;

    // are not required for validation
    Object.entries(rest).forEach(([key, val]) => {
      const isEmpty = val === "";

      // count as error if empty
      errors[key] = isEmpty;
      if (isEmpty) errorCount++;

      // pw len check
      if ("password" === key && val.length < 8) {
        errors[key] = true;
        errorCount++;
      }
    });

    setErrors(errors);

    if (errorCount) return;

    // submit form
    setDisplay({
      modal: false,
      loading: true,
    });

    // remove empty fields
    const data = Object.fromEntries(
      Object.entries(user).filter(([_, v]) => v !== "")
    );

    const { error, message } = await addUser(data);
    setResponse({
      error,
      message,
    });

    setDisplay({
      loading: false,
      snackbar: true,
    });

    // reset form
    handleCancel();
  };

  const handleOpen = () => {
    setDisplay({
      modal: true,
    });
  };

  const handleCloseDialog = () => {
    setDisplay({
      dialog: false,
    });
  };

  const handleClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setDisplay({
      snackbar: false,
    });
  };

  const handleCancel = () => {
    // reset form
    Object.keys(user).forEach((key) => (user[key] = ""));
    Object.keys(errors).forEach((key) => (errors[key] = false));

    setUser(user);
    setErrors(errors);
    setDisplay({
      modal: false,
    });
  };

  useEffect(() => {
    const fetchCompanies = async () => {
      const data = await getCompanies();
      setCompanies(data);
    };

    fetchCompanies();
  }, []);

  useEffect(() => {
    const fetchOrganizations = async () => {
      const data = await getOrganizations();
      setOrganizations(data);
    };

    fetchOrganizations();
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      const data = await getUsers();

      // get company details
      const { company_name, users } = data.find(
        (company) => id === company._id
      );

      const userList = users.map((user) => ({
        ...user,
        status: user.status === 1 ? "Active" : "Inactive",
        role: roleLabel(user.role),
        company: company_name,
        view: (
          <IconButton
            aria-label="View Account"
            component={Link}
            to={`/app/user/${user._id}`}
          >
            <AccountBox />
          </IconButton>
        ),
      }));

      setDisplay({
        loading: false,
      });
      setUsers(userList);
    };

    if (!response.error) {
      setDisplay({
        loading: true,
      });
      fetchUsers();
    }
  }, [response.error]);

  // useEffect(() => {
  //   const fetchRoles = async () => {
  //     const roles = await getRoles();

  //     console.log("ROLES :======>", roles);
  //   };

  //   fetchRoles();
  // }, []);

  const options = {
    onRowsDelete: (rows) => {
      setDisplay({
        dialog: true,
      });

      const forDeletion = rows.data.map((row) => users[row.index]["_id"]);
      setDeleted(forDeletion);
    },
  };

  // TODOS: remove fetchCompanies

  return (
    <>
      <PageTitle
        title="Users"
        button={
          <Button
            variant="contained"
            size="medium"
            color="secondary"
            startIcon={<Add />}
            onClick={handleOpen}
          >
            Add User
          </Button>
        }
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {display.loading ? (
            <EmproPaper />
          ) : (
            <MUIDataTable data={users} options={options} columns={tblUserHdr} />
          )}
        </Grid>
        <EmproModal
          open={display.modal}
          onClose={handleCancel}
          ariaLabel="user-form"
          ariaDescription="user-form"
        >
          <h3 className={classes.header}>
            Name <Person className={classes.iconStyle} />
          </h3>
          <div className={classes.mb5}>
            <FormControl className={classes.formControl}>
              <TextField
                id="first_name"
                name="first_name"
                label="First Name"
                value={user.first_name}
                required
                error={errors.first_name}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                id="last_name"
                name="last_name"
                label="Last Name"
                value={user.last_name}
                required
                error={errors.last_name}
                onChange={handleChange}
              />
            </FormControl>
          </div>
          <h3 className={classes.header}>
            Business <Business className={classes.iconStyle} />
          </h3>
          <div className={classes.mb5}>
            <FormControl
              className={classes.formControl}
              required
              error={errors.role}
            >
              <InputLabel id="role-label">Role</InputLabel>
              <Select
                labelId="role-label"
                id="role"
                name="role"
                value={user.role}
                onChange={handleChange}
              >
                {roles.map((role, key) => (
                  <MenuItem value={role.value} key={key}>
                    {role.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id="company-label">Company</InputLabel>
              <Select
                labelId="company-label"
                id="company"
                name="company"
                value={user.company}
                onChange={handleChange}
              >
                {companies.map(({ _id, company_name }, key) => (
                  <MenuItem value={_id} key={key}>
                    {company_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id="organization-label">Organisation</InputLabel>
              <Select
                labelId="organization-label"
                id="organization"
                name="organization"
                value={user.organization}
                onChange={handleChange}
              >
                {organizations.map(({ _id, name }, key) => (
                  <MenuItem value={_id} key={key}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <h3 className={classes.header}>
            Login <VpnKey className={classes.iconStyle} />
          </h3>
          <div className={classes.mb5}>
            <FormControl className={classes.formControl}>
              <TextField
                id="email"
                name="email"
                label="Email"
                type="email"
                value={user.email}
                required
                error={errors.email}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                id="password"
                name="password"
                label="Password"
                type="password"
                value={user.password}
                required
                error={errors.password}
                onChange={handleChange}
                helperText="Password should be at least 8 characters."
              />
            </FormControl>
          </div>
          <div className={classes.footer}>
            <Button variant="contained" size="medium" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              variant="contained"
              size="medium"
              color="primary"
              startIcon={<Save />}
              onClick={handleSubmit}
            >
              Create
            </Button>
          </div>
        </EmproModal>
      </Grid>
      <EmproSnackbar
        display={display}
        onClose={handleClose}
        response={response}
      />
      <ConfirmDelete
        display={display}
        onClose={handleCloseDialog}
        onDelete={handleDelete}
      />
    </>
  );
}
