import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  // Quiz
  formControl: {
    margin: theme.spacing(1, 0),
    width: "100%",
  },
  mb5: {
    margin: theme.spacing(0, 0, 5, 0),
  },
  header: {
    color: "#032564",
    display: "flex",
    marginBottom: 0,
    fontSize: "20px",
    justifyContent: "space-between",
    alignItems: "center",
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
  },
  iconStyle: {
    color: "#9b9cbe",
    fontSize: "2rem",
  },

  // Questions
  wrapper: {
    margin: theme.spacing(3, 0, 5, 0),
    width: "650px",
  },
  bgContainer: {
    backgroundColor: "#032564",
    borderRadius: 5,
    height: 210,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },
  logo: {
    width: "100%",
  },
  noImg: {
    color: "#fff",
    fontSize: "22px !important",
  },
}));
