import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  snackbar: {
    color: "#fff",
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2, 3),
  },
  message: {
    fontWeight: 600,
    marginLeft: theme.spacing(1),
  },
  success: {
    background: "#4caf50",
  },
  error: {
    background: "#f44336",
  },
}));
