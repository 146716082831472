import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    background: "rgb(0, 0, 0, 0.5)",
  },
  paper: {
    display: "flex",
    height: "300px",
    justifyContent: "center",
    alignItems: "center",
  },
}));
