import { Paper, CircularProgress } from "@material-ui/core";

// styles
import useStyles from "./styles";

const EmproPaper = () => {
  const classes = useStyles();
  return (
    <Paper elevation={3} className={classes.paper}>
      <CircularProgress />
    </Paper>
  );
};

export default EmproPaper;
