import React, { useState } from "react";
import { AppBar, Toolbar, IconButton, Menu, MenuItem } from "@material-ui/core";
import {
  Person as AccountIcon,
  ExitToApp as ExitToAppIcon,
} from "@material-ui/icons";
import classNames from "classnames";

// logo
import logo from "../../images/empro.tech.png";

// styles
import useStyles from "./styles";

// components
import { Typography } from "../Wrappers";

// helpers
import { roleLabel } from "../../helpers/utils";

// context
// import { useLayoutState, useLayoutDispatch } from "../../context/LayoutContext";
import {
  useUserState,
  useUserDispatch,
  signOut,
} from "../../context/UserContext";

export default function Header(props) {
  var classes = useStyles();

  // global
  const userDispatch = useUserDispatch();
  const { userData } = useUserState();

  console.log("Header > userData :=============>", userData);

  // local
  const [profileMenu, setProfileMenu] = useState(null);

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.logoContainer}>
          <img src={logo} alt="logo" className={classes.logo} />
        </div>
        <div className={classes.grow} />
        <IconButton
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={(e) => setProfileMenu(e.currentTarget)}
        >
          <AccountIcon classes={{ root: classes.headerIcon }} />
        </IconButton>
        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <Typography variant="h4" weight="medium">
              {userData.first_name} {userData.last_name}
            </Typography>
            <Typography className={classes.profileMenuLink}>
              {roleLabel(userData.role)}
            </Typography>
          </div>
          <MenuItem
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem
            )}
          >
            <AccountIcon className={classes.profileMenuIcon} /> Profile
          </MenuItem>
          <MenuItem onClick={() => signOut(userDispatch, props.history)}>
            <ExitToAppIcon className={classes.profileMenuIcon} /> Sign Out
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
