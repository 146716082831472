export const colors = [
  {
    name: "primary_color",
    label: "Primary Color",
    value: "",
  },
  {
    name: "secondary_color",
    label: "Secondary Color",
    value: "",
  },
  {
    name: "tertiary_color",
    label: "Tertiary Color",
    value: "",
  },
  {
    name: "neutral_color",
    label: "Neutral Color",
    value: "",
  },
  {
    name: "overlay_color",
    label: "Overlay Color",
    value: "",
  },
  {
    name: "accent_color",
    label: "Accent Color",
    value: "",
  },
];

export const roles = [
  // {
  //   name: "Super User",
  //   value: 0,
  // },
  {
    name: "Admin User",
    value: 2,
  },
  {
    name: "End User",
    value: 3,
  },
];

export const roleLabel = (role) => {
  let label = "End User";
  if (role === 0) label = "Super User";
  if (role === 1 || role === 2) label = "Admin User";

  return label;
};

export const nameInitials = (first, last) =>
  (first.charAt(0) + last.charAt(0)).toUpperCase();

export const companyName = (companies, id) => {
  const [company] = companies.filter(({ _id }) => _id === id);
  return company?.company_name;
};

export const tblUserHdr = [
  {
    name: "first_name",
    label: "First Name",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "last_name",
    label: "Last Name",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "email",
    label: "Email",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "company",
    label: "Company",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "org",
    label: "Organization",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "role",
    label: "Role",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "status",
    label: "Status",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "view",
    label: "View",
    options: {
      filter: false,
      sort: false,
    },
  },
];

export const tblCompanyHdr = [
  {
    name: "company_name",
    label: "Name",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "address",
    label: "Address",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "company_contact_person",
    label: "Contact Person",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "company_email",
    label: "Contact Email",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "company_contact_number",
    label: "Contact Number",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "view",
    label: "View",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "users",
    label: "Users",
    options: {
      filter: false,
      sort: false,
    },
  },
];

export const tblOrgHdr = [
  {
    name: "org_name",
    label: "Organization Name",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "org_street",
    label: "Address",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "industry",
    label: "Industry",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "view",
    label: "View",
    options: {
      filter: false,
      sort: false,
    },
  },
];

export const tblQuizHdr = [
  {
    name: "title",
    label: <strong>Title</strong>,
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "company",
    label: <strong>Company</strong>,
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "status",
    label: <strong>Status</strong>,
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "view",
    label: <strong>View</strong>,
    options: {
      filter: false,
      sort: false,
    },
  },
];

export const tblQuestionHdr = [
  {
    name: "question",
    label: <strong>Question</strong>,
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "choices",
    label: <strong>Choices</strong>,
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "answer",
    label: <strong>Answer</strong>,
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "action",
    label: <strong>Edit</strong>,
    options: {
      filter: false,
      sort: false,
    },
  },
];
