import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import {
  AccountTree,
  ArrowBack,
  Assessment,
  Business,
  Dashboard,
  Group,
  EmojiObjects,
  Settings,
  Help,
  Assignment,
} from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";

import { useUserState } from "../../context/UserContext";

const Sidebar = ({ location }) => {
  const { userData } = useUserState();

  // console.log("userData :=============>", userData);

  const classes = useStyles();
  const theme = useTheme();

  // global
  const { isSidebarOpened } = useLayoutState();
  const layoutDispatch = useLayoutDispatch();

  // local
  const [isPermanent, setPermanent] = useState(true);

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  let navigation = [];
  // super user
  if (userData.role === 0) {
    navigation = [
      {
        id: 0,
        label: "Dashboard",
        link: "/app/dashboard",
        icon: <Dashboard />,
      },
      {
        id: 1,
        label: "Parent Company",
        link: "/app/companies",
        icon: <Business />,
      },
      {
        id: 2,
        label: "Partner Account",
        link: "/app/organizations",
        icon: <AccountTree />,
      },
      { id: 3, type: "divider" },
      { id: 4, label: "Quiz", link: "/app/quizzes", icon: <Assignment /> },
      { id: 5, label: "Ideas Hub", link: "/app/ideas", icon: <EmojiObjects /> },
      { id: 6, label: "Reports", link: "/app/reports", icon: <Assessment /> },
      { id: 7, type: "divider" },
      { id: 8, label: "Support", link: "/app/support", icon: <Help /> },
    ];
  }

  // admin user
  if (userData.role === 2) {
    navigation = [
      {
        id: 0,
        label: "Dashboard",
        link: "/app/dashboard",
        icon: <Dashboard />,
      },
      { id: 1, label: "Users", link: "/app/users", icon: <Group /> },
      {
        id: 2,
        label: "Organisations",
        link: "/app/organizations",
        icon: <AccountTree />,
      },
      { id: 3, type: "divider" },
      { id: 4, label: "Ideas Hub", link: "/app/ideas", icon: <EmojiObjects /> },
      { id: 5, label: "Reports", link: "/app/reports", icon: <Assessment /> },
      { id: 6, type: "divider" },
      { id: 7, label: "Settings", link: "/app/settings", icon: <Settings /> },
      { id: 8, label: "Support", link: "/app/support", icon: <Help /> },
    ];
  }

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBack
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {navigation.map((link) => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          />
        ))}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
};

export default withRouter(Sidebar);
