import { Snackbar, Paper } from "@material-ui/core";
import { CheckCircle, Error } from "@material-ui/icons";
import classNames from "classnames";

// styles
import useStyles from "./styles";

const EmproSnackbar = ({ display, onClose, response }) => {
  const classes = useStyles();
  return (
    <Snackbar open={display.snackbar} autoHideDuration={6000} onClose={onClose}>
      {response.error ? (
        <Paper
          elevation={6}
          variant="elevation"
          className={classNames(classes.snackbar, classes.error)}
        >
          <Error /> <div className={classes.message}>{response.message}</div>
        </Paper>
      ) : (
        <Paper
          elevation={6}
          variant="elevation"
          className={classNames(classes.snackbar, classes.success)}
        >
          <CheckCircle />{" "}
          <div className={classes.message}>{response.message}</div>
        </Paper>
      )}
    </Snackbar>
  );
};

export default EmproSnackbar;
