import { useEffect, useReducer, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Grid,
  IconButton,
  FormControl,
  TextField,
} from "@material-ui/core";
import { AccountTree, Add, Save, Person, LocationOn } from "@material-ui/icons";
import MUIDataTable from "mui-datatables";

// components
import PageTitle from "../../components/PageTitle";
import EmproPaper from "../../components/Loading/Paper";
import EmproModal from "../../components/Modal";
import EmproSnackbar from "../../components/Snackbar";
import { ConfirmDelete } from "../../components/Dialog";

// data
import {
  addOrganization,
  deleteOrganization,
  getOrganizations,
} from "../../hooks/useRequest";

// helpers
import { tblOrgHdr } from "../../helpers/utils";
import { iOrganization, iDisplay } from "../../helpers/props";

// styles
import useStyles from "./styles";

export default function Organizations() {
  const classes = useStyles();

  const [organization, setOrganization] = useReducer(
    (prev, next) => ({ ...prev, ...next }),
    iOrganization
  );
  const [errors, setErrors] = useReducer(
    (prev, next) => ({ ...prev, ...next }),
    { org_name: false }
  );
  const [response, setResponse] = useReducer(
    (prev, next) => ({ ...prev, ...next }),
    { error: "", message: "" }
  );
  const [display, setDisplay] = useReducer(
    (prev, next) => ({ ...prev, ...next }),
    iDisplay
  );
  const [organizations, setOrganizations] = useState([]);
  const [deleted, setDeleted] = useState([]);

  const handleSubmit = async () => {
    let errorCount = 0;

    // required field
    const { org_name } = organization;
    if (!org_name) {
      errors["org_name"] = true;
      errorCount++;
    }

    setErrors(errors);

    if (errorCount) return;

    // reset error response
    setResponse({
      error: "",
      message: "",
    });

    // submit form
    setDisplay({
      modal: false,
      loading: true,
    });

    const { error, message } = await addOrganization(organization);

    setResponse({
      error,
      message,
    });

    setDisplay({
      loading: false,
      snackbar: true,
    });

    // reset form
    handleCancel();
  };

  const handleDelete = async () => {
    setDisplay({
      dialog: false,
      loading: true,
    });

    // reset error response
    setResponse({
      error: "",
      message: "",
    });

    const promises = deleted.map(async (id) => await deleteOrganization(id));
    const results = await Promise.all(promises);

    if (results.length) {
      setResponse({
        error: results[0].error,
        message: results[0].message,
      });
    }

    setDisplay({
      loading: false,
      snackbar: true,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOrganization({
      [name]: value,
    });
  };

  const handleClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setDisplay({
      snackbar: false,
    });
  };

  const handleCloseDialog = () => {
    setDisplay({
      dialog: false,
    });
  };

  const handleOpen = () => {
    setDisplay({
      modal: true,
    });
  };

  const handleCancel = () => {
    // reset form
    Object.keys(organization).forEach((key) => (organizations[key] = ""));
    Object.keys(errors).forEach((key) => (errors[key] = false));

    setOrganization(organization);
    setErrors(errors);
    setDisplay({
      modal: false,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await getOrganizations();

      const organizations = data.map((org) => ({
        ...org,
        view: (
          <IconButton
            aria-label="View Account"
            component={Link}
            // to={`/app/organization/${org._id}`}
          >
            <AccountTree />
          </IconButton>
        ),
      }));

      setDisplay({
        loading: false,
      });
      setOrganizations(organizations);
    };

    if (!response.error) {
      setDisplay({
        loading: true,
      });
      fetchData();
    }
  }, [response.error]);

  const options = {
    onRowsDelete: (rows) => {
      setDisplay({
        dialog: true,
      });
      const forDeletion = rows.data.map(
        (row) => organizations[row.index]["_id"]
      );
      setDeleted(forDeletion);
    },
  };

  return (
    <>
      <PageTitle
        title="Partner Account"
        button={
          <Button
            variant="contained"
            size="medium"
            color="secondary"
            startIcon={<Add />}
            onClick={handleOpen}
          >
            Add Partner Account
          </Button>
        }
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {display.loading ? (
            <EmproPaper />
          ) : (
            <MUIDataTable
              data={organizations}
              options={options}
              columns={tblOrgHdr}
            />
          )}
        </Grid>
        <EmproModal
          open={display.modal}
          onClose={handleCancel}
          ariaLabel="organization-form"
          ariaDescription="organization-form"
        >
          <h3 className={classes.header}>
            Name <AccountTree className={classes.iconStyle} />
          </h3>
          <div className={classes.mb5}>
            <FormControl className={classes.formControl}>
              <TextField
                id="name"
                name="org_name"
                label="Organisation Name"
                value={organization.org_name}
                required
                error={errors.org_name}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                id="industry"
                name="industry"
                label="Industry"
                value={organization.industry}
                // required
                // error={errors.industry}
                onChange={handleChange}
              />
            </FormControl>
          </div>
          <h3 className={classes.header}>
            Address <LocationOn className={classes.iconStyle} />
          </h3>
          <div className={classes.mb5}>
            <FormControl className={classes.formControl}>
              <TextField
                id="street"
                name="org_street"
                label="Street"
                value={organization.org_street}
                onChange={handleChange}
              />
            </FormControl>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <FormControl className={classes.formControl}>
                  <TextField
                    id="city"
                    name="org_city"
                    label="City"
                    value={organization.org_city}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <TextField
                    id="zip"
                    name="org_zip"
                    label="Zip"
                    value={organization.org_zip}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </div>
          <h3 className={classes.header}>
            Contact <Person className={classes.iconStyle} />
          </h3>
          <div className={classes.mb5}>
            <FormControl className={classes.formControl}>
              <TextField
                id="person"
                name="org_contact_person"
                label="Name"
                value={organization.org_contact_person}
                onChange={handleChange}
              />
            </FormControl>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    id="email"
                    name="org_contact_email"
                    label="Email"
                    type="email"
                    value={organization.org_contact_email}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    id="phone"
                    name="org_contact_phone"
                    label="Phone"
                    value={organization.org_contact_phone}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </div>
          <div className={classes.footer}>
            <Button variant="contained" size="medium" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              variant="contained"
              size="medium"
              color="primary"
              startIcon={<Save />}
              onClick={handleSubmit}
            >
              Create
            </Button>
          </div>
        </EmproModal>
      </Grid>
      <EmproSnackbar
        display={display}
        onClose={handleClose}
        response={response}
      />
      <ConfirmDelete
        display={display}
        onClose={handleCloseDialog}
        onDelete={handleDelete}
      />
    </>
  );
}
