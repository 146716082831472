import { Box } from "@material-ui/core";
import { Typography } from "../../components/Wrappers/Wrappers";

export const TabPanel = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`ideas-tabpanel-${index}`}
    aria-labelledby={`ideas-tab-${index}`}
    {...other}
  >
    {value === index && (
      <Box p={2}>
        <Typography>{children}</Typography>
      </Box>
    )}
  </div>
);

export const AriaProps = (i) => ({
  id: `ideas-tab-${i}`,
  "aria-controls": `ideas-tabpanel-${i}`,
});
