import axios from "axios";

// axios config
axios.defaults.baseURL = process.env.REACT_APP_API;
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.common["x-access-token"] = sessionStorage.getItem(
  "id_token"
);

export const doLogin = async (email, pass) => {
  let res = [];

  const uri = "/user/login";
  const params = { username: email, password: pass };

  try {
    const { data } = await axios.post(uri, params);
    res = data?.data;
  } catch (error) {
    console.error("ERROR [doLogin]:", error);
  }
  return res;
};

export const updateUser = async (id, params) => {
  let res = [];

  const uri = `user/${id}`;

  try {
    const { data } = await axios.put(uri, params);
    res = data?.data;
  } catch (error) {
    console.error("ERROR [updateUser]:", error);
  }
  return res;
};

// for super user
export const getUsers = async () => {
  let res = [];
  try {
    // const { data } = await axios.get("user/list/all");
    const { data } = await axios.get("company/list/all/users");
    res = data?.data;
  } catch (error) {
    console.error("ERROR [getUsers]:", error);
  }
  return res;
};

// for admin user
export const getUsersByCompany = async () => {
  let res = [];
  try {
    const { data } = await axios.get("user/list/all/company");
    res = data?.data;
  } catch (error) {
    console.error("ERROR [getUsers]:", error);
  }
  return res;
};

export const getUser = async (id) => {
  let res = [];
  try {
    const { data } = await axios.get(`user/${id}`);
    res = data?.data;
  } catch (error) {
    console.error("ERROR [getUser]:", error);
  }
  return res;
};

export const getUserActivity = async (id) => {
  let res = [];
  try {
    const { data } = await axios.get(
      `useractivity/list/filter/user?page=1&limit=10&user=${id}`
    );
    res = data?.data;
  } catch (error) {
    console.error("ERROR [getUserActivity]:", error);
  }
  return res;
};

export const addUser = async (params) => {
  let uri = "";
  switch (params.role) {
    case 0:
      uri = "/user/register/super";
      break;
    case 2:
      uri = "/user/register/admin";
      break;
    default:
      uri = "/user/register";
      break;
  }

  try {
    const { data } = await axios.post(uri, params);
    return data;
  } catch (e) {
    const { error, message } = e.response.data;
    return {
      error,
      message,
    };
  }
};

export const deleteUser = async (id) => {
  try {
    const { data } = await axios.delete(`user/one/${id}`);
    return data;
  } catch (e) {
    const { error, message } = e.response.data;
    return {
      error,
      message,
    };
  }
};

export const uploadVideo = async (id, params) => {
  try {
    const { data } = await axios.post(
      `company/upload/video/intro/${id}`,
      params,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return data;
  } catch (e) {
    const { error, message } = e.response.data;
    return {
      error,
      message,
    };
  }
};

export const uploadLogo = async (id, params) => {
  try {
    const { data } = await axios.post(
      `company/upload/image/logo/${id}`,
      params,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return data;
  } catch (e) {
    const { error, message } = e.response.data;
    return {
      error,
      message,
    };
  }
};

export const addCompany = async (params) => {
  try {
    const { data } = await axios.post("company/add", params);
    return data;
  } catch (e) {
    const { error, message } = e.response.data;
    return {
      error,
      message,
    };
  }
};

export const updateCompany = async (id, params) => {
  let res = [];

  const uri = `company/${id}`;

  try {
    const { data } = await axios.put(uri, params);
    res = data?.data;
  } catch (error) {
    console.error("ERROR [updateCompany]:", error);
  }
  return res;
};

export const deleteCompany = async (id) => {
  try {
    const { data } = await axios.delete(`company/one/${id}`);
    return data;
  } catch (e) {
    const { error, message } = e.response.data;
    return {
      error,
      message,
    };
  }
};

export const getCompanies = async () => {
  let res = [];
  try {
    const { data } = await axios.get("company/list/all");
    res = data?.data;
  } catch (error) {
    console.error("ERROR [getCompanies]:", error);
  }
  return res;
};

export const getCompany = async (id) => {
  let res = [];
  try {
    const { data } = await axios.get(`company/${id}`);
    res = data?.data;
  } catch (error) {
    console.error("ERROR [getCompany]:", error);
  }
  return res;
};

export const getCompanyUsers = async () => {
  let res = [];
  try {
    const { data } = await axios.get(`userrole/list/company/all`);
    res = data?.data;
  } catch (error) {
    console.error("ERROR [getCompanyUsers]:", error);
  }
  return res;
};

// does not exists yet??
export const getOrganizations = async () => {
  let res = [];
  try {
    // const { data } = await axios.get("organizations/list/all");
    const data = {
      data: [
        {
          _id: "666e612f3d44df3eae6b2123",
          org_name: "BrightFutures Foundation",
          industry: "Non-profit/Philanthropy",
          org_street: "123 Hope Street",
          org_city: "Charitytown",
          org_zip: "45125",
          org_email: "info@brightfutures.org",
          org_phone: "4512-45451",
        },
        {
          _id: "666e612f3d44df3eae6b2124",
          org_name: "StellarTech Solutions",
          industry: "Information Technology",
          org_street: "456 Tech Avenue",
          org_city: "Innovation City",
          org_zip: "5612",
          org_email: "info@stellartech.com",
          org_phone: "4515-5321",
        },
        {
          _id: "666e612f3d44df3eae6b2125",
          org_name: "GreenGrowth Environmental Consultants",
          industry: "Environmental Services",
          org_street: "789 Eco Circle",
          org_city: "Sustainabilityville",
          org_zip: "54123",
          org_email: "info@greengrowth.com",
          org_phone: "5849-4512",
        },
        {
          _id: "666e612f3d44df3eae6b2126",
          org_name: "HealthHub Wellness Center",
          industry: "Healthcare",
          org_street: "101 Wellness Drive",
          org_city: "Healthville",
          org_zip: "89566",
          org_email: "info@healthhub.com",
          org_phone: "451-5452",
        },
        {
          _id: "666e612f3d44df3eae6b2127",
          org_name: "EduNation Learning Institute",
          industry: "Education",
          org_street: "222 Knowledge Lane",
          org_city: "Learntown",
          org_zip: "75452",
          org_email: "info@edunation.org",
          org_phone: "7845-4512",
        },
      ],
    };
    res = data?.data;
  } catch (error) {
    console.error("ERROR [getOrganizations]:", error);
  }
  return res;
};

export const addOrganization = async (params) => {
  try {
    // const { data } = await axios.post("organization/add", params);
    const data = {
      error: false,
      message: "Organization was added successfully.",
      data: params,
    };
    return data;
  } catch (e) {
    const { error, message } = e.response.data;
    return {
      error,
      message,
    };
  }
};

export const deleteOrganization = async (id) => {
  try {
    // const { data } = await axios.delete("organization/one", id);
    const data = {
      error: false,
      message: "Organization was deleted successfully.",
      data: [],
    };
    return data;
  } catch (e) {
    const { error, message } = e.response.data;
    return {
      error,
      message,
    };
  }
};

export const getOrganization = async (id) => {
  try {
    // const { data } = await axios.get("organization/one", id);
    const data = {
      error: false,
      message: "Retreive Organization data successfully.",
      data: {
        _id: "666e612f3d44df3eae6b2127",
        org_name: "EduNation Learning Institute",
        industry: "Education",
        org_street: "222 Knowledge Lane",
        org_city: "Learntown",
        org_zip: "75452",
        org_email: "info@edunation.org",
        org_phone: "7845-4512",
      },
    };

    return data;
  } catch (e) {
    const { error, message } = e.response.data;
    return {
      error,
      message,
    };
  }
};

export const getQuizzes = async () => {
  let res = [];
  try {
    const { data } = await axios.get("quiz/list/all");
    res = data?.data;
  } catch (error) {
    console.error("ERROR [getQuizzes]:", error);
  }
  return res;
};

export const addQuiz = async (params) => {
  try {
    const { data } = await axios.post("quiz/add", params);
    return data;
  } catch (e) {
    const { error, message } = e.response.data;
    return {
      error,
      message,
    };
  }
};

export const updateQuiz = async (id, params) => {
  let res = [];

  const uri = `quiz/${id}`;

  try {
    const { data } = await axios.put(uri, params);
    res = data?.data;
  } catch (error) {
    console.error("ERROR [updateQuiz]:", error);
  }
  return res;
};

export const deleteQuiz = async (id) => {
  try {
    const { data } = await axios.delete(`quiz/one/${id}`);
    return data;
  } catch (e) {
    const { error, message } = e.response.data;
    return {
      error,
      message,
    };
  }
};

export const getQuestionDetails = async (id) => {
  let res = [];
  try {
    const { data } = await axios.get(`question/${id}`);
    res = data?.data;
  } catch (error) {
    console.error("ERROR [getQuestionDetails]:", error);
  }
  return res;
};

export const getQuestions = async () => {
  let res = [];
  try {
    const { data } = await axios.get("question/list/all");
    res = data?.data;
  } catch (error) {
    console.error("ERROR [getQuestions]:", error);
  }
  return res;
};

export const getQuizQuestions = async (quizId) => {
  let res = [];
  try {
    const { data } = await axios.get(`quiz/${quizId}`);
    res = data?.data;
  } catch (error) {
    console.error("ERROR [getQuizQuestions]:", error);
  }
  return res;
};

export const addQuestion = async (params) => {
  const { quiz, question } = params;
  try {
    const { data } = await axios.post("question/add", question);

    // get question id
    // and add it to quiz
    const questionData = data.data;
    const questions = [...quiz.questions, questionData._id];

    // update quiz - no api yet
    await updateQuiz(quiz.id, questions);

    return data;
  } catch (e) {
    const { error, message } = e.response.data;
    return {
      error,
      message,
    };
  }
};

export const addQuestionBackground = async (id, params) => {
  const uri = `question/upload/background/${id}`;
  try {
    const { data } = await axios.post(uri, params, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return data;
  } catch (e) {
    const { error, message } = e.response.data;
    return {
      error,
      message,
    };
  }
};

export const deleteQuestion = async (id) => {
  try {
    const { data } = await axios.delete(`question/one/${id}`);
    return data;
  } catch (e) {
    const { error, message } = e.response.data;
    return {
      error,
      message,
    };
  }
};

export const getIdeas = async () => {
  let res = [];
  try {
    const { data } = await axios.get("ideashub/list/all");
    res = data?.data;
  } catch (error) {
    console.error("ERROR [getIdeas]:", error);
  }
  return res;
};

export const getIdeasByCompany = async () => {
  let res = [];
  try {
    const { data } = await axios.get("ideashub/list/company/all");
    res = data?.data;
  } catch (error) {
    console.error("ERROR [getIdeasByCompany]:", error);
  }
  return res;
};

export const updateIdea = async (id, params) => {
  let res = [];

  const uri = `ideashub/${id}`;

  try {
    const { data } = await axios.put(uri, params);
    res = data?.data;
  } catch (error) {
    console.error("ERROR [updateIdea]:", error);
  }
  return res;
};

export const getRoles = async () => {
  let res = [];
  try {
    const { data } = await axios.get("userrole/list/all");
    res = data?.data;
  } catch (error) {
    console.error("ERROR [getRoles]:", error);
  }
  return res;
};
