import { Modal } from "@material-ui/core";

// styles
import useStyles from "./styles";

const EmproModal = ({
  children,
  open,
  ariaLabel,
  ariaDescription,
  onClose,
}) => {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby={ariaLabel}
      aria-describedby={ariaDescription}
      className={classes.modal}
    >
      <div className={classes.modalContent}>{children}</div>
    </Modal>
  );
};

export default EmproModal;
