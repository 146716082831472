import { createContext, useContext, useReducer } from "react";
import { doLogin } from "../hooks/useRequest";

const UserStateContext = createContext();
const UserDispatchContext = createContext();

const userReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true, userData: { ...payload } };
    case "LOGIN_FAILURE":
      return { ...state, isAuthenticated: false };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false, userData: null };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userReducer, {
    isAuthenticated: !!sessionStorage.getItem("id_token"),
    userData: JSON.parse(sessionStorage.getItem("user_data")),
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
};

const useUserState = () => {
  const context = useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
};

const useUserDispatch = () => {
  const context = useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
};

// ###########################################################
const loginUser = async (
  dispatch,
  login,
  password,
  history,
  setIsLoading,
  setError
) => {
  setError(false);
  setIsLoading(true);

  try {
    const { user, token } = await doLogin(login, password);

    // check token and make sure role is either super or admin
    if (token && user.role < 3) {
      sessionStorage.setItem("id_token", token);
      sessionStorage.setItem("user_data", JSON.stringify(user));

      setError(false);
      setIsLoading(false);
      dispatch({ type: "LOGIN_SUCCESS", payload: { ...user } });

      history.push("/");
    } else {
      setError(true);
      setIsLoading(false);
    }
  } catch (error) {
    console.error("Error logging in:", error);
    setError(true);
    setIsLoading(false);
    dispatch({ type: "LOGIN_FAILURE" });
  }
};

const signOut = (dispatch, history) => {
  sessionStorage.removeItem("id_token");
  sessionStorage.removeItem("user_data");
  sessionStorage.clear();

  dispatch({ type: "SIGN_OUT_SUCCESS" });
  history.push("/login");
};

export { UserProvider, useUserState, useUserDispatch, loginUser, signOut };
