import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1, 0),
    width: "100%",
  },
  header: {
    color: "#032564",
    display: "flex",
    marginBottom: 0,
    fontSize: "20px",
    justifyContent: "space-between",
    alignItems: "center",
  },
  mb5: {
    margin: theme.spacing(0, 0, 5, 0),
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
  },
  iconStyle: {
    color: "#9b9cbe",
    fontSize: "2rem",
  },
  logoWrapper: {
    backgroundColor: "#032564",
    borderRadius: "5px",
    height: 150,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },
  logo: {
    display: "block",
    margin: "0 auto",
    width: "100%",
  },
  noLogo: {
    color: "#fff",
    fontSize: "32px !important",
  },
  preview: {
    background: "#9b9cbe",
    width: "auto",
    height: "80%",
  },
  btnWapper: {
    marginBottom: 25,
    marginTop: 25,
    textAlign: "center",
  },
}));
