import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  header: { fontWeight: 700 },
  table: {},
  loader: {
    margin: "2rem auto",
    textAlign: "center",
    width: "100%",
  },
}));
