import React from "react";
import { Grid } from "@material-ui/core";
// import { makeStyles } from "@material-ui/styles";
import MUIDataTable from "mui-datatables";

// components
import PageTitle from "../../components/PageTitle";

// data
const datatableData = [
  ["65f80cca68835c579c9fd3cd", "Empro", "Active"],
  ["65f80cec68835c579c9fd3d1", "Empro ABC", "Active"],
];

// const useStyles = makeStyles((theme) => ({
//   tableOverflow: {
//     overflow: "auto",
//   },
// }));

export default function Tables() {
  // const classes = useStyles();
  return (
    <>
      <PageTitle title="Companies" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <MUIDataTable
            title="Company List"
            data={datatableData}
            columns={["ID", "Company Name", "Status"]}
          />
        </Grid>
      </Grid>
    </>
  );
}
