import { useEffect, useState, useReducer } from "react";
import { useParams } from "react-router-dom";

import {
  ButtonGroup,
  Button,
  IconButton,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  FormControl,
  TextField,
} from "@material-ui/core";
import {
  Add,
  Edit,
  RadioButtonChecked,
  RadioButtonUnchecked,
  QuestionAnswer,
  Save,
} from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import MUIDataTable from "mui-datatables";

// components
import PageTitle from "../../components/PageTitle";
import EmproPaper from "../../components/Loading/Paper";
import EmproModal from "../../components/Modal";
import EmproSnackbar from "../../components/Snackbar";
import { ConfirmDelete } from "../../components/Dialog";
import { Typography, Upload } from "../../components/Wrappers";

// data
import {
  addQuestion,
  addQuestionBackground,
  deleteQuestion,
  getQuizQuestions,
  getQuestionDetails,
} from "../../hooks/useRequest";

// helpers
import { tblQuestionHdr } from "../../helpers/utils";
import { iQuiz, iDisplay, iQuestionErrors } from "../../helpers/props";

// styles
import useStyles from "./styles";

const getAnswer = (choices) => {
  const [answer] = choices?.filter((data) => data.is_correct);
  return answer?.text;
};

const getChoices = (options) => (
  <List>
    {options.map(({ text }, key) => (
      <ListItem key={key}>
        <ListItemIcon>
          {getAnswer(options) === text ? (
            <RadioButtonChecked />
          ) : (
            <RadioButtonUnchecked />
          )}
        </ListItemIcon>
        <ListItemText primary={text} />
      </ListItem>
    ))}
  </List>
);

export default function QuizDetails() {
  const classes = useStyles();
  const theme = useTheme();

  const numblocks = [1, 2, 3, 4];

  const { id: quizId } = useParams();

  const [errors, setErrors] = useReducer(
    (prev, next) => ({ ...prev, ...next }),
    { iQuestionErrors }
  );
  const [quiz, setQuiz] = useReducer(
    (prev, next) => ({ ...prev, ...next }),
    iQuiz
  );
  const [response, setResponse] = useReducer(
    (prev, next) => ({ ...prev, ...next }),
    { error: "", message: "" }
  );
  const [display, setDisplay] = useReducer(
    (prev, next) => ({ ...prev, ...next }),
    iDisplay
  );

  const [title, setTitle] = useState("");
  const [deleted, setDeleted] = useState([]);
  const [question, setQuestion] = useState("");
  const [questions, setQuestions] = useState([]);
  const [choices, setChoices] = useState({});
  const [answer, setAnswer] = useState("");
  const [file, setFile] = useState("");
  const [edit, setEdit] = useState(false);

  const handleEdit = async (id) => {
    const question = await getQuestionDetails(id);

    setEdit(true);

    console.log("QUESTION :====>", question);
    setQuestion(question);
    setDisplay({
      modal: true,
    });
  };

  const handleUpdate = async () => {
    console.log("UPDATE QUESTION :======>", file);
    const { _id } = question;

    setDisplay({
      loading: true,
    });

    const formData = new FormData();
    formData.append("image", file);

    await addQuestionBackground(_id, formData);

    setDisplay({
      loading: false,
    });
  };

  const handleDelete = async () => {
    setDisplay({
      dialog: false,
      loading: true,
    });

    // reset error response
    setResponse({
      error: "",
      message: "",
    });

    const promises = deleted.map(async (id) => await deleteQuestion(id));
    const results = await Promise.all(promises);

    if (results.length) {
      setResponse({
        error: results[0].error,
        message: results[0].message,
      });
    }

    setDisplay({
      loading: false,
      snackbar: true,
    });
  };

  const handleSubmit = async () => {
    // get values from array
    const values = Object.values(choices);
    const correct = values.indexOf(answer);
    const options = values.map((option, index) => ({
      text: option,
      is_correct: index === correct,
    }));

    // setup data object
    const params = {
      quiz: {
        id: quizId,
        questions: questions.map(({ _id }) => _id),
      },
      question: {
        text: question,
        category: "Multiple Choice",
        options: options,
        correct_index: correct,
        order: 0,
        background: "",
        comments: "N/A",
      },
    };

    let errorCount = 0;

    // required fields
    errors["question"] = !question;
    if (!question) errorCount++;

    numblocks.forEach((num) => {
      errors[`choice${num}`] = !choices[num - 1];
      if (!choices[num - 1]) errorCount++;
    });

    errors["answer"] = !answer;
    if (!answer) errorCount++;

    setErrors(errors);

    if (errorCount) return;

    // reset error response
    setResponse({
      error: "",
      message: "",
    });

    // submit form
    setDisplay({
      modal: false,
      loading: true,
    });

    const { error, message } = await addQuestion(params);
    // const error = true;
    // const message = "Trial only";

    setResponse({
      error,
      message,
    });

    setDisplay({
      loading: false,
      snackbar: true,
    });

    // reset form
    handleCloseModal();
  };

  const handleUpload = async (e) => {
    const { files } = e.target;
    setFile(URL.createObjectURL(files[0]));
  };

  const handleQuestion = (e) => {
    const { value } = e.target;
    setQuestion(value);
  };

  const handleAnswer = (e) => {
    const { value } = e.target;
    setAnswer(value);
  };

  const handleChoices = (e, i) => {
    const { value } = e.target;
    setChoices((prev) => ({
      ...prev,
      [i]: value,
    }));
  };

  const handleCloseSnackbar = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setDisplay({
      snackbar: false,
    });
  };

  const handleCloseDialog = () => {
    setDisplay({
      dialog: false,
    });
  };

  const handleOpen = () => {
    setEdit(false);
    setDisplay({
      modal: true,
    });
  };

  const handleCloseModal = () => {
    // reset form
    setQuestion("");
    setAnswer("");
    setChoices({});

    setErrors(iQuestionErrors);
    setDisplay({
      modal: false,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const { title, questions } = await getQuizQuestions(quizId);
      const items = questions.map(({ _id, text, options }) => ({
        _id,
        question: text,
        choices: getChoices(options),
        answer: getAnswer(options),
        action: () => (
          <IconButton
            aria-label="edit"
            color="primary"
            onClick={() => handleEdit(_id)}
          >
            <Edit />
          </IconButton>
        ),
      }));

      setDisplay({
        loading: false,
      });
      setTitle(title);
      setQuestions(items);
    };

    setDisplay({
      loading: true,
    });
    fetchData();
  }, [quizId]);

  const options = {
    onRowsDelete: (rows) => {
      setDisplay({
        dialog: true,
      });
      const forDeletion = rows.data.map((row) => questions[row.index]["_id"]);
      setDeleted(forDeletion);
    },
  };

  console.log("QUESTION :====>", question);

  return (
    <>
      <PageTitle
        title={title}
        button={
          <ButtonGroup>
            <Button
              variant="contained"
              size="medium"
              color="secondary"
              startIcon={<Add />}
              onClick={handleOpen}
            >
              Add Question
            </Button>
            {/* <Button
              variant="contained"
              size="medium"
              color="primary"
              startIcon={<QuestionAnswer />}
              onClick={handleOpen}
            >
              Upload Questions
            </Button> */}
          </ButtonGroup>
        }
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {display.loading ? (
            <EmproPaper />
          ) : (
            <MUIDataTable
              data={questions}
              columns={tblQuestionHdr}
              options={options}
            />
          )}
        </Grid>
        <EmproModal
          open={display.modal}
          onClose={handleCloseModal}
          ariaLabel="question"
          ariaDescription="question"
        >
          <h3 className={classes.header}>
            {edit ? "Edit" : "Add"} Question{" "}
            <QuestionAnswer className={classes.iconStyle} />
          </h3>
          <div className={classes.wrapper}>
            <div className={classes.bgContainer}>
              {file ? (
                <img src={file} alt={file} className={classes.logo} />
              ) : (
                <Typography className={classes.noImg}>
                  ADD BACKGROUND
                </Typography>
              )}
            </div>
            <Upload onUpload={handleUpload} />
            <FormControl className={classes.formControl}>
              <TextField
                id="question"
                label="Question"
                value={question.text}
                required
                error={errors.question}
                onChange={handleQuestion}
              />
            </FormControl>
            {question?.options?.length
              ? question.options.map(({ _id, text }, key) => (
                  <FormControl className={classes.formControl} key={key}>
                    <TextField
                      id={`choice${_id}`}
                      label={`Choice ${key + 1}`}
                      value={text}
                      required
                      error={errors[`choice${_id}`]}
                      onChange={(e) => handleChoices(e, _id)}
                    />
                  </FormControl>
                ))
              : numblocks.map((val, key) => (
                  <FormControl className={classes.formControl} key={key}>
                    <TextField
                      id={`choice${val}`}
                      label={`Choice ${val}`}
                      required
                      error={errors[`choice${val}`]}
                      onChange={(e) => handleChoices(e, key)}
                    />
                  </FormControl>
                ))}
            <FormControl className={classes.formControl}>
              <TextField
                id="answer"
                label="Answer"
                value={
                  question?.options?.length && getAnswer(question?.options)
                }
                required
                error={errors.answer}
                onChange={handleAnswer}
              />
            </FormControl>
          </div>
          <div className={classes.footer}>
            <Button
              variant="contained"
              size="medium"
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="medium"
              color="primary"
              startIcon={<Save />}
              onClick={edit ? handleUpdate : handleSubmit}
            >
              {edit ? "Update" : "Create"}
            </Button>
          </div>
        </EmproModal>
      </Grid>
      <EmproSnackbar
        display={display}
        onClose={handleCloseSnackbar}
        response={response}
      />
      <ConfirmDelete
        display={display}
        onClose={handleCloseDialog}
        onDelete={handleDelete}
      />
    </>
  );
}
