import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  capitalize,
  TextField,
} from "@material-ui/core";

export const ConfirmDelete = ({ display, onClose, onDelete }) => (
  <Dialog open={display.dialog} onClose={onClose}>
    <DialogTitle>Confirm Delete</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Are you sure you want to delete? This action cannot be undone.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button autoFocus onClick={onClose} variant="contained">
        Cancel
      </Button>
      <Button onClick={onDelete} color="primary" variant="contained">
        Delete
      </Button>
    </DialogActions>
  </Dialog>
);

export const ConfirmApproveReject = ({
  action,
  display,
  onClose,
  onChange,
  onConfirm,
}) => (
  <Dialog
    open={display.dialog}
    onClose={onClose}
    maxWidth="xs"
    fullWidth={true}
  >
    <DialogTitle>Confirm {capitalize(action)}</DialogTitle>
    <DialogContent>
      <DialogContentText>Do you want to continue?</DialogContentText>
      {action === "reject" && (
        <TextField
          autoFocus
          margin="dense"
          id="review_notes"
          name="review_notes"
          label="Review Notes"
          multiline
          fullWidth
          maxRows={4}
          onChange={onChange}
        />
      )}
    </DialogContent>
    <DialogActions>
      <Button autoFocus onClick={onClose} variant="contained">
        Cancel
      </Button>
      <Button onClick={onConfirm} color="primary" variant="contained">
        {capitalize(action)}
      </Button>
    </DialogActions>
  </Dialog>
);
