import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1, 0),
    width: "100%",
  },
  header: {
    color: "#032564",
    display: "flex",
    marginBottom: 0,
    fontSize: "20px",
    justifyContent: "space-between",
    alignItems: "center",
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
  },
  iconStyle: {
    color: "#9b9cbe",
    fontSize: "2rem",
  },
  account: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  large: {
    width: theme.spacing(20),
    height: theme.spacing(20),
    marginBottom: theme.spacing(3),
    fontSize: 72,
    fontWeight: "bold",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  text: {
    marginBottom: theme.spacing(1),
  },
  mb5: {
    margin: theme.spacing(0, 0, 5, 0),
  },
  mb2: {
    marginBottom: theme.spacing(2),
  },
}));
