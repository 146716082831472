import { useEffect, useState, useReducer } from "react";
import { Grid, Tab, Tabs, Table } from "@material-ui/core";

// components
import Widget from "../../components/Widget";
import PageTitle from "../../components/PageTitle";
import { ConfirmApproveReject } from "../../components/Dialog";
import { TblHeader, TblBody } from "./table";
import { TabPanel, AriaProps } from "./tab";

// context
import { useUserState } from "../../context/UserContext";

// data
import {
  getIdeas,
  getIdeasByCompany,
  updateIdea,
} from "../../hooks/useRequest";

// helper
import { iDisplay, iIdeas } from "../../helpers/props";

// styles
// import useStyles from "./styles";

export default function IdeasHub() {
  // const classes = useStyles();
  const { userData } = useUserState();
  const isAdmin = userData.role === 2;

  const [tab, setTab] = useState(0);

  // setup tab data
  const [fresh, setFresh] = useState([]);
  const [accepted, setAccepted] = useState([]);
  const [rejected, setRejected] = useState([]);

  const [data, setData] = useReducer(
    (prev, next) => ({ ...prev, ...next }),
    iIdeas
  );
  const [display, setDisplay] = useReducer(
    (prev, next) => ({ ...prev, ...next }),
    { ...iDisplay, loading: true }
  );

  const onChangeTab = (_, tab) => {
    setTab(tab);
  };

  const handleConfirm = async () => {
    setDisplay({
      dialog: false,
      loading: true,
    });

    await updateIdea(data.id, {
      status: "approve" === data.action ? 1 : 2,
      review_notes: data?.review_notes,
    });

    setDisplay({
      loading: false,
    });
  };

  const handleAction = (id, action) => {
    setData({
      id,
      action,
    });
    setDisplay({
      dialog: true,
    });
  };

  const handleClose = () => {
    setDisplay({
      dialog: false,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      [name]: value,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = isAdmin ? await getIdeasByCompany() : await getIdeas();

      const fresh = data.filter((i) => i.status === 0);
      setFresh(fresh);

      const accepted = data.filter((i) => i.status === 1);
      setAccepted(accepted);

      const rejected = data.filter((i) => i.status === 2);
      setRejected(rejected);

      setDisplay({
        loading: false,
      });
    };

    if (display.loading) {
      fetchData();
    }
  }, [display.loading, isAdmin]);

  return (
    <>
      <PageTitle title="Ideas Hub" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget title="" disableWidgetMenu>
            <Tabs value={tab} onChange={onChangeTab} aria-label="ideas hub">
              <Tab label="New Submission" {...AriaProps(0)} />
              <Tab label="Published" {...AriaProps(1)} />
              <Tab label="Rejected" {...AriaProps(2)} />
            </Tabs>
            <TabPanel value={tab} index={0}>
              <Table aria-label="new submission">
                <TblHeader action={true} />
                <TblBody
                  action={true}
                  data={fresh}
                  loading={display.loading}
                  handleAction={handleAction}
                />
              </Table>
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <Table aria-label="accepted submission">
                <TblHeader />
                <TblBody data={accepted} loading={display.loading} />
              </Table>
            </TabPanel>
            <TabPanel value={tab} index={2}>
              <Table aria-label="rejected submission">
                <TblHeader notes={true} />
                <TblBody
                  data={rejected}
                  loading={display.loading}
                  notes={true}
                />
              </Table>
            </TabPanel>
          </Widget>
        </Grid>
      </Grid>
      <ConfirmApproveReject
        action={data?.action}
        display={display}
        onClose={handleClose}
        onChange={handleChange}
        onConfirm={handleConfirm}
      />
    </>
  );
}
