import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  bgContainer: {
    backgroundColor: "#032564",
    borderRadius: 5,
    height: 210,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },
  logo: {
    width: "100%",
  },
  noImg: {
    color: "#fff",
    fontSize: "22px !important",
  },
  formControl: {
    margin: theme.spacing(1, 0),
    width: "100%",
  },
  header: {
    color: "#032564",
    display: "flex",
    marginBottom: 0,
    fontSize: "20px",
    justifyContent: "space-between",
    alignItems: "center",
  },
  mb3: {
    margin: theme.spacing(0, 0, 3, 0),
  },
  wrapper: {
    margin: theme.spacing(3, 0, 5, 0),
    width: "650px",
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
  },
  iconStyle: {
    color: "#9b9cbe",
    fontSize: "2rem",
  },
  w100: {
    width: "100%",
  },
  divider: { clear: "both", marginTop: theme.spacing(15) },
  dividerLabel: {
    position: "absolute",
    top: "-25px",
    background: "#fff",
    left: "45%",
    padding: "5px",
  },
  uploadWrapper: {
    textAlign: "center",
    position: "relative",
    paddingTop: 50,
  },
}));
